<template lang="">
    <div class="field">
        <div class="grid formgrid mt-6 px-4">
            <div class="col-12">
                <label class="font-medium">Novo Contrato</label>
                <div class="mt-4 mb-3"><label>Dados da Importação:</label></div>
                <div class="grid formgrid">
                    <div style="display: flex" v-for="(cadastro, index) in formData.cadastrosConclusao" :key="index">
                        <Card style="width: 15rem; margin-left: 1rem; background-color: #d9d9d9">
                            <template #content>
                                <div style="text-align: center">
                                    <p style="font-size: 26px; font-weight: bold; color: #000000">{{ cadastro.items.length }}</p>
                                    <p style="font-size: 18px; font-weight: bold; color: #000000">{{ cadastro.sheetName }}</p>
                                </div>
                            </template>
                        </Card>
                    </div>
                </div>
            </div>
            <div class="field col-12 mt-5">
                <TabView>
                    <TabPanel v-for="(cadastro, index) in formData.cadastrosConclusao" :key="index" :header="cadastro.sheetName">
                        <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'CARGO'">
                            <Column field="nome" header="Nome">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.nome }}</div>
                                </template>
                            </Column>
                            <Column field="descricao" header="Descrição eSocial">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.descricao }}</div>
                                </template>
                            </Column>
                        </DataTable>
                        <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'SETOR'">
                            <Column field="nome" header="Nome">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.nome }}</div>
                                </template>
                            </Column>
                            <Column field="descricao" header="Descrição eSocial">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.descricao }}</div>
                                </template>
                            </Column>
                        </DataTable>
                        <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'CLIENTE'">
                            <Column field="cnpjEmpresa" header="CNPJ Empresa">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cnpjEmpresa }}</div>
                                </template>
                            </Column>
                            <Column field="razaoSocial" header="Razão Social">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.razaoSocial }}</div>
                                </template>
                            </Column>
                            <Column field="nomeFantasia" header="Nome Fantasia">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.nomeFantasia }}</div>
                                </template>
                            </Column>
                            <Column field="IE" header="Inscrição Estadual">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.IE }}</div>
                                </template>
                            </Column>
                            <Column field="inscricaoMunicipal" header="Inscrição Municipal">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.inscricaoMunicipal }}</div>
                                </template>
                            </Column>
                            <Column field="CNAE" header="CNAE">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.CNAE }}</div>
                                </template>
                            </Column>
                            <Column field="grauRisco" header="Grau de Risco">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.grauRisco }}</div>
                                </template>
                            </Column>
                            <Column field="CEP" header="CEP">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.CEP }}</div>
                                </template>
                            </Column>
                            <Column field="logradouro" header="Logradouro">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.logradouro }}</div>
                                </template>
                            </Column>
                            <Column field="numero" header="Número">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.numero }}</div>
                                </template>
                            </Column>
                            <Column field="cidade" header="Cidade">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cidade }}</div>
                                </template>
                            </Column>
                            <Column field="dataFechamentoContrato" header="Data Fechamento Contrato">
                                <template #body="slotProps">
                                    <div>{{ $filters.formatDateOnly(slotProps.data?.dataFechamentoContrato) }}</div>
                                </template>
                            </Column>
                            <Column field="emailContato" header="E-mail Contato">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.emailContato }}</div>
                                </template>
                            </Column>
                        </DataTable>
                        <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'UNIDADE'">
                            <Column field="cnpjEmpresa" header="CNPJ Empresa">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cnpjEmpresa }}</div>
                                </template>
                            </Column>
                            <Column field="razaoSocial" header="Razão Social">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.razaoSocial }}</div>
                                </template>
                            </Column>
                            <Column field="nomeFantasia" header="Nome Fantasia">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.nomeFantasia }}</div>
                                </template>
                            </Column>
                            <Column field="IE" header="Inscrição Estadual">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.IE }}=</div>
                                </template>
                            </Column>
                            <Column field="inscricaoMunicipal" header="Inscrição Municipal">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.inscricaoMunicipal }}</div>
                                </template>
                            </Column>
                            <Column field="CNAE" header="CNAE">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.CNAE }}</div>
                                </template>
                            </Column>
                            <Column field="grauRisco" header="Grau de Risco">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.grauRisco }}</div>
                                </template>
                            </Column>
                            <Column field="CEP" header="CEP">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.CEP }}</div>
                                </template>
                            </Column>
                            <Column field="logradouro" header="Logradouro">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.logradouro }}</div>
                                </template>
                            </Column>
                            <Column field="numero" header="Número">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.numero }}</div>
                                </template>
                            </Column>
                            <Column field="cidade" header="Cidade">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cidade }}</div>
                                </template>
                            </Column>
                            <Column field="dataFechamentoContrato" header="Data Fechamento Contrato">
                                <template #body="slotProps">
                                    <div>{{ $filters.formatDateOnly(slotProps.data?.dataFechamentoContrato) }}</div>
                                </template>
                            </Column>
                            <Column field="emailContato" header="E-mail Contato">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.emailContato }}</div>
                                </template>
                            </Column>
                        </DataTable>
                        <DataTable :value="cadastro.items" responsiveLayout="scroll" v-if="cadastro?.sheetName === 'FUNCIONÁRIO'">
                            <Column field="cnpjUnidade" header="CNPJ Unidade">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cnpjUnidade }}</div>
                                </template>
                            </Column>
                            <Column field="nomeFuncionario" header="Nome Funcionário">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.nomeFuncionario }}</div>
                                </template>
                            </Column>
                            <Column field="cargo" header="Cargo">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cargo ? slotProps.data?.cargo : ' ' }}</div>
                                </template>
                            </Column>
                            <Column field="setor" header="Setor">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.setor }}</div>
                                </template>
                            </Column>
                            <Column field="matricula" header="Matrícula">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.matricula }}</div>
                                </template>
                            </Column>
                            <Column field="dtNascimento" header="Dt. Nascimento">
                                <template #body="slotProps">
                                    <div>{{ $filters.formatDateOnly(slotProps.data?.dtNascimento) }}</div>
                                </template>
                            </Column>
                            <Column field="sexo" header="Sexo">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.sexo }}</div>
                                </template>
                            </Column>
                            <Column field="situacao" header="Situação">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.situacao }}</div>
                                </template>
                            </Column>
                            <Column field="dtAdmissao" header="Dt. Admissão">
                                <template #body="slotProps">
                                    <div>{{ $filters.formatDateOnly(slotProps.data?.dtAdmissao) }}</div>
                                </template>
                            </Column>
                            <Column field="cpf" header="CPF">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.cpf }}</div>
                                </template>
                            </Column>
                            <Column field="ctps" header="CTPS">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.ctps }}</div>
                                </template>
                            </Column>
                            <Column field="estadoCtps" header="Estado CTPS">
                                <template #body="slotProps">
                                    <div>{{ slotProps.data?.estadoCtps }}</div>
                                </template>
                            </Column>
                            <Column field="dataUltimoExame" header="Data Último Exame">
                                <template #body="slotProps">
                                    <div>{{ $filters.formatDateOnly(slotProps.data?.dataUltimoExame) }}</div>
                                </template>
                            </Column>
                        </DataTable>
                    </TabPanel>
                </TabView>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button
                            label="Fechar"
                            class="p-button p-button-success w-15rem"
                            @click="
                                () => {
                                    this.$router.push('/importacao-cadastro-estrutura');
                                }
                            "
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { showSuccess } from '../../../utils/Toast';

export default {
    props: {
        formData: Object
    },
    data() {
        return {
            submitted: false
        };
    },
    mounted() {
        showSuccess(this.$toast, 'Importação salva com sucesso!');
    }
};
</script>

<style scoped lang="scss">
::v-deep(.tipo-importacao) {
    border-radius: 6px;
}
</style>
